import { EnvironmentConfig, ProjectConfig } from './environment.interface';

const sandbox:EnvironmentConfig = {
  production: false,
  sentryEnvName: 'sandbox',
  corePath: 'https://sandbox-offerservice.poshvine.com/core_service/v1',
  corePathV2: 'https://sandbox-api.poshvine.com/cs/v1',
  offerPath: 'https://sandbox-offerservice.poshvine.com/offer_service/v1',
  giftPathV2: 'https://sandbox-offerservice.poshvine.com/offer_service/v2',
  offerPathV2: 'https://sandbox-api.poshvine.com/ps/v1',
  apiUrlBsV1: 'https://sandbox-api.poshvine.com/bs/v1/',
  apiUrlPaV1: 'https://sandbox-api.poshvine.com/pa/v1/',
  apiUrlPsV1: 'https://sandbox-api.poshvine.com/ps/v1/',
  apiUrlLsV1: 'https://sandbox-api.poshvine.com/ls/v1/',
  baseUrl: 'https://sandbox-api.poshvine.com/',
  apiUrl: 'https://rewardshub-stage-api.poshvine.com/api/v1/',
  apiUrlBsV1Internal: 'https://sandbox-api.poshvine.com/bs/internal',
  stagingUrl: 'https://rewardshub-stage-api.poshvine.com/',
  perksUrl: 'https://sandbox-api.poshvine.com/ps/rewardshub/',
  rewardsHubApiUrl: 'https://rewardshub-stage-api.poshvine.com/api/v1/',
  campaignPath: 'https://sandbox-api.poshvine.com/cas/v1/',
  campaignEnterprisePath: 'https://sandbox-api.poshvine.com/cas/enterprise/',
}
const production:EnvironmentConfig = {
  production: true,
  sentryEnvName: 'production',
  corePath: 'https://api-customer.poshvine.com/cs/v1',
  corePathV2: 'https://api-customer.poshvine.com/cs/v1',
  offerPath: 'https://api-customer.poshvine.com/offer_service/v1',
  giftPathV2: 'https://offers-api.poshvine.com/offer_service/v2',
  offerPathV2: 'https://api-customer.poshvine.com/ps/v1',
  apiUrlBsV1: 'https://api-customer.poshvine.com/bs/v1/',
  apiUrlPaV1: 'https://api-customer.poshvine.com/pa/v1/',
  apiUrlPsV1: 'https://api.poshvine.com/ps/v1/',
  apiUrlLsV1: 'https://api-customer.poshvine.com/ls/v1/',
  baseUrl: 'https://api-customer.poshvine.com/',
  apiUrl: 'https://rewardshub-stage-api.poshvine.com/api/v1/',
  apiUrlBsV1Internal: 'https://api-customer.poshvine.com/bs/internal',
  stagingUrl: 'https://rewardshub-stage-api.poshvine.com/',
  perksUrl: 'https://api-customer.poshvine.com/ps/rewardshub/',
  rewardsHubApiUrl: 'https://rewards-api.poshvine.com/api/v1/',
  campaignPath: 'https://api-customer.poshvine.com/cas/v1/',
  campaignEnterprisePath: 'https://api-customer.poshvine.com/cas/enterprise/',
}

const visaContactless:ProjectConfig = {
  projectName: 'visaContactless',
  projectTitle: 'Visa Tap to Pay Offer',
  gTagTrackingId: 'G-T7WT65M2CC',
  emailId: 'taptopay-offer@razorpay.com',
  faqLink: 'https://www.visa.co.in/pay-with-visa/taptopay-offer.html',
  clientId: 'be08c7f8-232b-471d-a141-1041cd635234',
  projectId: 'da0d8732-186f-4ff9-a8a4-d609104be4f3',
  analytics:
    'https://assets.adobedtm.com/8e068bb67c90/07a78f6404f1/launch-15106500ef68-staging.min.js',
  gmApiKey: 'AIzaSyCX4nc8CaDOF9TIWqmKQo48SLo05LOJf3Y', //'AIzaSyC2AtPyxZua0AcvWyoPZi-M_WuXoOo_gRc'
  secretKey: '000102030405060708090a0b0c0d0e0f',
  ipstackKey: '022375a36968cebce87e10e1c6447ef6',
  SENTRY_ORG: 'rzp',
  SENTRY_PROJECT: 'poshvine-visa-contactless',
  SENTRY_AUTH_TOKEN:
    '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',

  sentryDsn:
    'https://06e43ed670c486f933118ce1dbe334ad@o515678.ingest.us.sentry.io/4507288014487552',
  homePage: {
    mainHeading: 'Tap to Pay with your Visa Card and get Amazon Vouchers',
    stepOneHeading: 'Tap to Pay using your Visa Card',
    stepOneDetails: 'Refer to the offer details for transaction eligibility to get Amazon vouchers.',
    stepTwoHeading: 'Login and claim your vouchers!',
    steoTwoDetails: 'Claim your voucher after 15 days from your last eligible Tap to pay transaction.',
    signUpHeading: 'Unlock your Vouchers',
  },
  offerPage: {
    mainHeading: 'Your vouchers are waiting for you!',
    subHeading: 'Voucher Unlocked',
    skelatonLength: 2,
    showUnlockOfferFlag: true
  },
  privacyPolicyPage: {
    website: 'https://visataptopay.poshvine.com',
    projectName: 'Visa Tap To Pay',
  }
}
const visaActivation:ProjectConfig = {
  projectName: 'visaActivation',
  projectTitle: 'Visa Activation Offer',
  gTagTrackingId: 'G-8DNQ84Z7MF',
  emailId: 'activation-offer@razorpay.com',
  faqLink: 'https://www.visa.co.in/pay-with-visa/card-reactivation-terms.html',
  clientId: 'df473ac8-c9fa-4fe8-baf4-dda4e87848c5',
  projectId: '3638a526-ef5d-4a92-b665-7e1df1c78119',
  // analytics and sentry changes needs to update
  analytics:
    'https://assets.adobedtm.com/8e068bb67c90/07a78f6404f1/launch-5e1bff55b076.min.js',
  gmApiKey: 'AIzaSyCX4nc8CaDOF9TIWqmKQo48SLo05LOJf3Y', //'AIzaSyC2AtPyxZua0AcvWyoPZi-M_WuXoOo_gRc'
  secretKey: '000102030405060708090a0b0c0d0e0f',
  ipstackKey: '022375a36968cebce87e10e1c6447ef6',
  SENTRY_ORG: 'rzp',
  SENTRY_PROJECT: 'poshvine-visa-contactless',
  SENTRY_AUTH_TOKEN:
    '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',
  sentryDsn:
    'https://06e43ed670c486f933118ce1dbe334ad@o515678.ingest.us.sentry.io/4507288014487552',
  homePage: {
    mainHeading: 'Activate your Visa Credit Card and get an Amazon Prime Membership',
    stepOneHeading: 'Spend INR2000 using your Visa Credit Card',
    stepOneDetails: 'Input card details to check eligibility and refer to the terms and conditions for further details.',
    stepTwoHeading: 'Login and claim your vouchers!',
    steoTwoDetails: 'Claim your Amazon Prime Membership 15 days after you spend INR2000.',
    signUpHeading: 'Unlock your benefit',
  },
  offerPage: {
    mainHeading: 'Your benefit is waiting for you!',
    subHeading: 'Voucher Unlocked',
    skelatonLength: 1,
    showUnlockOfferFlag: false
  },
  privacyPolicyPage: {
    website: 'https://visaactivation.poshvine.com/',
    projectName: 'Visa Reactivation',
  }
}

export const commonEnvObject = { sandbox, production, visaContactless, visaActivation }