export const ERROR_CODES = {
  UserNotEligible: 'UserNotEligible',
  UserNotQualified: 'UserNotQualified',
};

export const ERROR_MESSAGES = {
  [ERROR_CODES.UserNotEligible]: "Sorry, you are not eligible for this program.",
  [ERROR_CODES.UserNotQualified]: "You are eligible but have not qualified yet.",
};

export const PROJECT_NAME = {
  VISA_CONTACTLESS: 'visaContactless',
  VISA_ACTIVATION: 'visaActivation'
}